import React from 'react'
import Styled from 'styled-components'

import { StaticQuery, graphql} from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Seo from '../components/Seo'
import Main, {colors} from '../styles/Styles'
import Header from '../components/Header'
import Button from '../components/Button'
import Md from '../components/Markdown'
import Footer from '../components/Footer'

const query = graphql`
query {
	strapiTeamPage {
		MainTitle
		MainText {
			data {
				childMarkdownRemark {
					html
				}
			}
		}
		Title
		AwardsTitle
		AwardsText {
			data {
				childMarkdownRemark {
					html
				}
			}
		}
		Cover {
			alternativeText
			childStrapiCloudinaryBridge {
				gatsbyImageData
			}
		}
		Awards {
			Title
			Description
		}
		team_members {
			Name
			ShortBio
			ProPic {
				alternativeText
				childStrapiCloudinaryBridge {
					gatsbyImageData(
						aspectRatio:1.4,
						transformations:[
							"c_fill",
							"g_auto",
						]
					)
				}
			}
			Url
		}
	}
}
`

const Line = Styled.div`
width:100%;
height:0;
border: .14vw solid ${() => colors.black};	//2

@media (orientation:portrait){
	border: .51vw solid ${() => colors.black};	//2
}
`

function TeamPage ({strapiTeamPage : tp}){
	return (
		<TeamPageStyles>
			<Header/>
			
			<Seo title={tp.Title} description={tp.MainTitle} type="website"/>
			{!!tp.Cover &&
			<GatsbyImage className="main" alt={tp.Cover.alternativeText} image={getImage(tp.Cover.childStrapiCloudinaryBridge)} draggable={false}/>
			}
			
			<div className="container">
			
			<h2>{tp.MainTitle}</h2>
			<Md className="description">{tp.MainText.data.childMarkdownRemark.html}</Md>
			
			<section id="us">
				{tp.team_members.map(({Name, ShortBio, Url, ProPic}) => 
					<div key={Url} className="person">
						<div className="buttonCont">
							<Button url={'/team-members/'+Url} title={Name} width="100%" height="100%">
								{!!ProPic[0] &&
								<GatsbyImage image={getImage(ProPic[0].childStrapiCloudinaryBridge)} alt={ProPic.alternativeText}/>
								}
							</Button>
						</div>
						<h6>{Name.toLowerCase()}</h6>
						<p>{ShortBio}</p>
					</div>
				)}
			</section>
			
			<h2 className="awards">{tp.AwardsTitle}</h2>
			<Md className="awards">{tp.AwardsText.data.childMarkdownRemark.html}</Md>
			
			<section id="awards">
				
				{tp.Awards.map(({Title, Description}, id) => 
					<div key={id}>
						<Line/>
						<p><span>{Title}</span> {Description}</p>
					</div>
				)
				}
			</section>
			
			</div>
			
			<Footer className="footer"/>
		</TeamPageStyles>
	)//
}

const TeamPageStyles = Styled(Main)`

padding:0;

&, .container{
	
	text-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	
	white-space:pre;
	
}

.container{
	width:100%;
	
	@media (orientation:portrait){
		box-sizing:border-box;
		padding:0 3.85vw;
	}
}

img.main {
	margin-top:5.28vw;	//76
	width:100%;
	
	@media (orientation:portrait){
		margin-top:17.95vw;	//70
	}
}

h2 {
	margin:3.47vw 0 .69vw;	//50 10
	
	@media (orientation:portrait){
		margin:12.82vw 0 2.56vw;	//50 10
	}
}

p {
	margin:0;
}

.description {
	
	width:80%;
	
	p {
		white-space:break-spaces;
	}
}

#us {
	box-sizing:border-box;
	margin:20.83vw;	//300
	padding:0 11.5vw;	//165
	width:100%;
	
	display:flex;
	justify-content:space-between;
	
	gap:2vw;
	
	@media (orientation:portrait){
		flex-direction:column;
		
		padding:0;
		margin:25.64vw 0 76.92vw;
		
		gap:20.51vw;
	}
	
	
	.person {
		white-space:break-spaces;
		text-align:left;
		
		flex-basis:24.31vw;
		
		.buttonCont{
			width:100%;
			height:17.36vw;
		}
		
		h6{
			margin:2.08vw 0 .35vw;	//30 5
			
			@media (orientation:portrait){
				margin:5.13vw 0 1.28vw;	//20 5
			}
		}
		
		p{
			margin:0;
			
			@media (orientation:portrait){
				font-size:5.13vw;
			}
		}
		
		.gatsby-image-wrapper {
			height:100%;
		}
		
		@media (orientation:portrait){
			width:100%;
			.buttonCont{
				height:64.10vw;	//250
			}
		}
	}
}

h2.awards {
	margin:0;
}

.awards {
	margin:.69vw 0 6.94vw;	//10 100
	
	white-space:break-spaces;
	
	@media (orientation:portrait){
		width:100%;
		
		margin:1.28vw 0 12.82vw;	//5 50
	}
}

#awards{
	width:37.5vw;	//540
	
	@media (orientation:portrait){
		width:100%;
	}
	
	p {
		text-align:left;
		white-space:break-spaces;
		
		margin:2.08vw 0;	//30
		
		@media (orientation:portrait){
			margin:5.13vw 0;	//20
		}
		
		span {
			color:${() => colors.red};
		}
	}
}

.footer {
	margin-top:20.83vw;	//300
	
	@media (orientation:portrait){
		margin-top:51.28vw;	//200
	}
}
`

const Page = () => (
	<StaticQuery
	query={query}
	render={TeamPage}
	/>
);//

export default Page
